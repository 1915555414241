const features = [
  {
    title: 'Summarized Case Laws',
    description: 'Quickly access concise summaries of case laws, crafted by advanced AI algorithms.',
    icon: 'book-open',
    benefits: [
      'Save time with succinct case summaries',
      'Enhance understanding with AI-generated insights'
    ]
  },
  {
    title: 'Robust Cross-Referencing',
    description: 'Seamlessly cross-reference cases and statutes with our intuitive features.',
    icon: 'link',
    benefits: [
      'Efficiently connect related legal documents',
      'Ensure comprehensive legal analysis'
    ]
  },
  {
    title: 'Timely Legal Alerts',
    description: 'Stay informed with alerts on legal developments that impact your practice.',
    icon: 'bell',
    benefits: [
      'Never miss crucial updates',
      'Receive personalized notifications'
    ]
  }
];

const Features = () => {
  return (
    <div id="features" className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-primary font-heading font-semibold tracking-wide uppercase">
            Features
          </h2>
          <p className="mt-2 text-3xl leading-8 font-heading font-bold tracking-tight text-secondary sm:text-4xl">
            Everything you need for legal research
          </p>
        </div>

        <div className="mt-10">
          <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.title} className="relative">
                <div className="absolute flex items-center justify-center h-12 w-12 rounded bg-primary text-white shadow">
                  <i className={`fas fa-${feature.icon} text-xl`}></i>
                </div>
                <div className="ml-16">
                  <h3 className="text-lg leading-6 font-heading font-medium text-secondary">
                    {feature.title}
                  </h3>
                  <p className="mt-2 text-base text-text font-sans">
                    {feature.description}
                  </p>
                  <ul className="mt-4 space-y-2">
                    {feature.benefits.map((benefit) => (
                      <li key={benefit} className="flex items-center text-text font-sans">
                        <i className="fas fa-check text-accent mr-2"></i>
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features; 