const Footer = () => {
  return (
    <footer id="contact" className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="col-span-1 md:col-span-2">
            <h3 className="text-sm font-heading font-semibold text-secondary tracking-wider uppercase mb-4">
              Contact Information
            </h3>
            <div className="space-y-3 text-text">
              <p><span className="font-medium">Company:</span> AETHER LAND GAMES SL</p>
              <p><span className="font-medium">NIF/CIF:</span> B88493218</p>
              <p><span className="font-medium">Address:</span> PS EXPOSICIO NUM85 P1 PTA1 (BARCELONA)</p>
              <div className="flex items-center space-x-2">
                <i className="fas fa-phone text-primary"></i>
                <a href="tel:+34915999659" className="hover:text-primary transition-colors">
                  +34 915 999 659
                </a>
              </div>
              <div className="flex items-center space-x-2">
                <i className="fas fa-envelope text-primary"></i>
                <a href="mailto:contact@aetherlex.com" className="hover:text-primary transition-colors">
                  contact@aetherlex.com
                </a>
              </div>
            </div>
          </div>
          <div>
            <h3 className="text-sm font-heading font-semibold text-secondary tracking-wider uppercase">
              Company
            </h3>
            <ul className="mt-4 space-y-4">
              <li>
                <a href="/about" className="text-base text-text hover:text-primary">
                  About Us
                </a>
              </li>
              <li>
                <a href="/careers" className="text-base text-text hover:text-primary">
                  Careers
                </a>
              </li>
              <li>
                <a href="/blog" className="text-base text-text hover:text-primary">
                  Blog
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-sm font-heading font-semibold text-secondary tracking-wider uppercase">
              Resources
            </h3>
            <ul className="mt-4 space-y-4">
              <li>
                <a href="/help" className="text-base text-text hover:text-primary">
                  Help Center
                </a>
              </li>
              <li>
                <a href="/privacy" className="text-base text-text hover:text-primary">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/terms" className="text-base text-text hover:text-primary">
                  Terms of Service
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-200">
          <p className="text-center text-text">
            © {new Date().getFullYear()} AETHER LAND GAMES SL. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 