import Header from './components/Header';
import Hero from './components/Hero';
import Overview from './components/Overview';
import Customization from './components/Customization';
import Features from './components/Features';
import ConversionSection from './components/ConversionSection';
import Footer from './components/Footer';

function App() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <Hero onDemoRequest={() => {}} />
      <Overview />
      <Customization />
      <Features />
      <ConversionSection />
      <Footer />
    </div>
  );
}

export default App; 