const Hero = ({ onDemoRequest }) => {
  const scrollToContact = (e) => {
    e.preventDefault();
    const element = document.getElementById('contact');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const scrollToStreamline = (e) => {
    e.preventDefault();
    const element = document.getElementById('streamline');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <div id="about" className="relative bg-background overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="relative z-10 py-16 lg:py-24">
          {/* Content Column - Now centered */}
          <div className="max-w-3xl mx-auto text-center">
            {/* Headlines */}
            <h1 className="text-4xl tracking-tight font-heading font-bold text-secondary sm:text-5xl md:text-6xl">
              <span className="block">Transforming</span>
              <span className="block">Legal Research</span>
              <span className="block text-primary mt-2">with Intelligent Insights</span>
            </h1>

            {/* Description */}
            <p className="mt-6 text-lg text-text font-sans mx-auto">
              Navigate the complexities of legal research with ease and precision. AetherLex empowers legal professionals with instant access to summarized case laws, robust cross-referencing features, and timely alerts on legal developments that matter.
            </p>

            {/* CTA Buttons */}
            <div className="mt-8 flex flex-col sm:flex-row gap-4 justify-center">
              <a
                href="#streamline"
                onClick={scrollToStreamline}
                className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded text-white bg-primary hover:bg-opacity-90 transition duration-150 shadow"
              >
                <i className="fas fa-arrow-right mr-2"></i>
                Get Started
              </a>
              <button
                onClick={scrollToContact}
                className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded text-primary bg-white hover:bg-background transition duration-150 shadow"
              >
                <i className="fas fa-envelope mr-2"></i>
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero; 