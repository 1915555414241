const Header = () => {
  const scrollToSection = (sectionId) => (e) => {
    e.preventDefault();
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <header className="bg-white shadow-sm sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          {/* Logo */}
          <div className="flex items-center">
            <a href="/" className="flex items-center space-x-2">
              <i className="fas fa-balance-scale text-2xl text-primary"></i>
              <span className="text-xl font-heading font-bold text-secondary">AetherLex</span>
            </a>
          </div>

          {/* Navigation */}
          <nav className="hidden md:flex items-center space-x-8">
            <a 
              href="#about" 
              onClick={scrollToSection('about')}
              className="text-text hover:text-primary transition-colors"
            >
              About
            </a>
            <a 
              href="#features" 
              onClick={scrollToSection('features')}
              className="text-text hover:text-primary transition-colors"
            >
              Features
            </a>
            <a 
              href="#customizable" 
              onClick={scrollToSection('customizable')}
              className="text-text hover:text-primary transition-colors"
            >
              Customizable
            </a>
            <a 
              href="#conversion" 
              onClick={scrollToSection('conversion')}
              className="text-text hover:text-primary transition-colors"
            >
              Conversion
            </a>
          </nav>

          {/* CTA Button */}
          <div className="flex items-center space-x-4">
            <a
              href="#contact"
              onClick={scrollToSection('contact')}
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded text-white bg-primary hover:bg-opacity-90 transition duration-150 shadow"
            >
              <i className="fas fa-envelope mr-2"></i>
              Contact Us
            </a>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button className="text-text hover:text-primary p-2">
              <i className="fas fa-bars text-xl"></i>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header; 