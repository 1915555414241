const Customization = () => {
  return (
    <div id="customizable" className="py-16 bg-background">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-heading font-bold text-secondary sm:text-4xl">
            Customizable to Your Practice Needs
          </h2>
          <p className="mt-4 text-lg text-text font-sans">
            Customizable and intuitive, AetherLex adapts to your unique practice needs, providing a personalized research experience that grows with your firm.
          </p>
          <div className="mt-10 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <div className="bg-white p-6 rounded shadow">
              <i className="fas fa-cogs text-4xl text-primary mb-4"></i>
              <h3 className="text-lg font-heading font-medium text-secondary">Custom Workflows</h3>
              <p className="mt-2 text-text">Tailor the platform to match your workflow</p>
            </div>
            <div className="bg-white p-6 rounded shadow">
              <i className="fas fa-puzzle-piece text-4xl text-primary mb-4"></i>
              <h3 className="text-lg font-heading font-medium text-secondary">Integration Ready</h3>
              <p className="mt-2 text-text">Seamlessly connects with your tools</p>
            </div>
            <div className="bg-white p-6 rounded shadow">
              <i className="fas fa-chart-line text-4xl text-primary mb-4"></i>
              <h3 className="text-lg font-heading font-medium text-secondary">Scalable Solution</h3>
              <p className="mt-2 text-text">Grows with your practice needs</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customization; 