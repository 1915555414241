const Overview = () => {
  const scrollToContact = (e) => {
    e.preventDefault();
    const element = document.getElementById('contact');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div id="streamline">
            <h2 className="text-3xl font-heading font-bold text-secondary sm:text-4xl">
              Streamline Your Legal Research
            </h2>
            <p className="mt-3 text-lg text-text font-sans">
              By leveraging state-of-the-art AI technology, AetherLex transforms traditional legal research into a streamlined, efficient process. Say goodbye to endless hours sifting through volumes of text and hello to intelligent insights at your fingertips.
            </p>
            <div className="mt-8 space-y-4">
              <div className="flex items-center">
                <i className="fas fa-check-circle text-2xl text-primary mr-4"></i>
                <span className="text-text">Advanced AI Analysis</span>
              </div>
              <div className="flex items-center">
                <i className="fas fa-search text-2xl text-primary mr-4"></i>
                <span className="text-text">Smart Search Capabilities</span>
              </div>
              <div className="flex items-center">
                <i className="fas fa-clock text-2xl text-primary mr-4"></i>
                <span className="text-text">Real-time Updates</span>
              </div>
            </div>
            <div className="mt-8">
              <div className="inline-flex rounded shadow">
                <a
                  href="#contact"
                  onClick={scrollToContact}
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded text-white bg-primary hover:bg-opacity-90 transition duration-150"
                >
                  <i className="fas fa-envelope mr-2"></i>
                  Contact Us
                </a>
              </div>
            </div>
          </div>
          <div className="mt-10 lg:mt-0 flex justify-center">
            <div className="p-8 bg-background rounded-lg shadow-lg">
              <i className="fas fa-gavel text-6xl text-primary"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview; 